import http from "../http-common";

class ChapterService {
  createchapter(data) {
    return http.post('/chapter', data);
  }
  getchapters(id) {
    return http.get(`/chapter?name=`+id);
  }
  getchapter(id) {
    return http.get(`/chapter/` + id);
  }
  updatechapter(id, data) {
    return http.put(`/chapter/${id}`, data);
  }
  updateno(id, data) {
    return http.put(`/chapter/updateno/${id}`, data);
  }
  deletechapter(id) {
    return http.delete(`/chapter/` + id);
  }
}

export default new ChapterService();