<template>
  <div class="container">
    <div class="card-body mt-3">
                <div class="form-group mt-3">
                  <label for="username">รายละเอียด</label>
                  <vue-editor              
              use-custom-image-handler
              v-model="user.name"
            />
                </div>
                <div class="form-group mt-3">
                  <label for="username">รูปภาพ</label><br>
                  <img :src="image" v-if="image" style="width:100px">
                  <input id="file" class="form-control mt-3" type="file" accept="image/*" @change="onFileChange"  />
                </div>

            <button
              type="button"
              class="btn btn-success btn-md mt-3"
              @click="save()"
            >
              บันทึก
            </button>
              </div>
  </div>
</template>

<script>
import UrlService from "../services/UrlService";
import LinkService from "../services/LinkService";
import axios from 'axios';
import MainService from '../services/MainService';

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      list: [],
      user: {},
      user_id: 0,
      hash: 0,
      title: "",
      roles: [],
      image:''
    };
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    onFileChange(evt) {
  const files = evt.target.files || evt.dataTransfer.files;
      // const selectedImage = evt.target.files[0]; // get first file
      // this.createBase64Image(selectedImage);
      if (!files.length) return;
      this.onUploadFileImage(files[0]);
      // this.createBase64Image(selectedImage);     
    },
    onUploadFileImage(selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile); // appending file
      //  sending file to the backend
      //console.log(this.filename);
      var link = LinkService.geturl()
      // console.log(this.data.type);
      const regex = /([\u0E00-\u0E7F]+)/gmu;
const str = selectedFile.name;
let m;
// console.log(selectedFile.type);
let checkthai = ''
let name = selectedFile.name
let typefile = (selectedFile.type).split('/')
while ((m = regex.exec(str)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
        regex.lastIndex++;
    }
    
    // The result can be accessed through the `m`-variable.
    m.forEach(() => {
        // console.log(`Found match, group ${groupIndex}: ${match}`);
checkthai = 1
    });
}
if (checkthai == 1) {
  name = this.makeid(5)+'.'+typefile[1]
}
// console.log(name);
var http = link + '/uploadimg?name=' + name +'&&path=/';
      axios
        .post(http, formData)
        .then(() => {
          this.user.image = link + '/uploads/'+name;
          this.image =  link + '/uploads/'+name;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteurl(id){
UrlService.deleteurl(id).then(()=>{
  this.getUsers();
})
    },
    getid(id) {
      this.user_id = id;
      if (this.user_id != 0) {
        this.title = "แก้ไขข้อมูล URL";
        // console.log(this.user_id);
        UrlService.geturl(this.user_id).then((res) => {
          // console.log(res.data);
          this.user = res.data;
          this.hash = this.user.password;
        });
      } else {
        this.title = "เพิ่มข้อมูล URL";
        this.user = [];
      }
    },
    save() {
      // if (this.user.firstname == "") {
      //   alert("กรุณากรอกชื่อ");
      // } else if (this.user.lastname == "") {
      //   alert("กรุณากรอกนามสกุล");
      // }else
      if (this.user.name == "" || this.user.name == null) {
        alert("กรุณากรอกชื่อ");
        // } else if (this.user.role_id == "") {
        //   alert("กรุณาเลือกสิทธิ์");
      } else {
        var userdata = {
          name: this.user.name,
          image: this.user.image,
        };
        MainService.updatemain(1, userdata).then(() => {
          this.getUsers()
          this.$toast.success("บันทึกสำเร็จ", {
    timeout: 2000
});
        });
      }
    },
    getUsers() {
      MainService.getmain(1).then((res) => {
        this.user = res.data;
        this.image = this.user.image
      });
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
body {
  background-color: gray;
}
</style>
