<template>
  <div class="container">
    <div class="row mt-5 mb-5">
      <div class="col-md-6">
        <div class="card">
  <div class="card-body">
    <div v-for="s in liststu" :key="s.id">
    <div class="mb-3">
  <label for="exampleFormControlInput1" class="form-label">รหัสนิสิต</label>
  <input type="number" class="form-control" id="exampleFormControlInput1" v-model="s.no">
</div>
<div class="mb-3">
  <label for="exampleFormControlInput1" class="form-label">ชื่อ-นามสกุล</label>
  <input type="text" class="form-control" id="exampleFormControlInput1" v-model="s.name">
</div>
<a v-if="liststu.length > 1" @click="deletestu(s.id)" class="btn btn-danger btn-sm"  style="color:white">ลบข้อมูลนิสิต</a>
<hr style="border: 1px solid black;">
</div>
    <a @click="addstu()" class="btn btn-sm btn-primary" style="color:white">เพิ่มสมาชิก</a>
  </div>
</div>
      </div>
      <div class="col-md-6"></div>
      <div class="col-md-6 mt-3">
        <div class="card">
  <div class="card-body">
    <div>
    <div class="mb-3">
  <label for="exampleFormControlInput1" class="form-label">ชื่อโครงงาน</label>
  <input type="text" class="form-control" id="exampleFormControlInput1" v-model="data.title">
</div>
<div class="mb-3">
  <label for="exampleFormControlInput1" class="form-label">อาจารย์ที่ปรึกษา</label>
  <select class="form-select form-select-sm" aria-label=".form-select-sm example" v-model="data.advisor" >
  <option v-for="(i,r) in advisor" :key="r" :value="i.id">{{i.firstname}} {{i.lastname}}</option>
</select>
</div>
<div class="mb-3">
  <label for="exampleFormControlInput1" class="form-label">วันที่เลือกสอบ</label>
  <div class="form-check mt-3" v-for="(m, mi) in date" :key="mi + 1">
                <label class="form-check-label" :for="m.id">
                <input
                  class="form-check-input"
                  type="radio"
                  name="type"
                  :id="m.id"
                  v-model="data.dateId"
                  :value="m.id"
                />
                  {{convertdatetothai(m.date)}}
                </label>
              </div>
</div>
</div>
    <a @click="save()" class="btn btn-sm btn-success" style="color:white">บันทึก</a>
  </div>
</div>
      </div>
    </div>
</div>
</template>

<script>
import ProjectService from '../services/ProjectService'

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  components:{
  },
  data() {
    return {
      list:[],
      data:{},
      liststu:[],
      date:[],
      stuid:[],
      stulist:[],
      advisor:[]
    };
  },
  mounted() {
    this.getdate()
    this.getadvisor()
    this.liststu.push({
      id:1,
      no:'',
      name:''
    })
  
  },
  methods: {
    getadvisor(){
ProjectService.getadvisor().then((res)=>{
        this.advisor = res.data
        console.log(this.advisor);
      })
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
    getdate(){
      ProjectService.getdate().then((res)=>{
        this.date = res.data
      })
    
    },
    deletestu(id){
      for (let i = 0; i < this.liststu.length; i++) {
        if(this.liststu[i].id === id) {
                        this.liststu.splice(i, 1);
                        break;
                    }
      }
    },
    save(){
      var statusstu = 1 
      for (let s = 0; s < this.liststu.length; s++) {
       if (this.liststu[s].no == '' || this.liststu[s].no == null || this.liststu[s].name == '' || this.liststu[s].name == null) {
        statusstu = 0
       }
       if (this.liststu.length == s+1) {
        if (statusstu == 0) {
          alert('กรุณากรอกข้อมูลสมาชิกให้ถูกต้อง')
        }else{
    if (this.data.title == '' || this.data.title == null) {
        alert('กรุณากรอกชื่อโครงการ')
      }else  if (this.data.advisor == '' || this.data.advisor == null) {
        alert('กรุณากรอกชื่ออาจารย์ที่ปรึกษา')
      }else if (this.data.dateId == '' || this.data.dateId == null) {
        alert('กรุณาเลือกวันที่เลือกสอบ')
      }else{
        for (let stu = 0; stu < this.liststu.length; stu++) {
        var stuarr = {
          no :this.liststu[stu].no,
          firstname_lastname :this.liststu[stu].name
        }
        ProjectService.createstu(stuarr).then((res)=>{
          this.stulist.push(res.data.id)
          if (this.liststu.length == stu+1) {
            var bookarr = {
              stuId:this.stulist,
              title:this.data.title,
              dateId:this.data.dateId,
              advisor:this.data.advisor,
              status:1
            }
            ProjectService.createbook(bookarr).then(()=>{
              this.$toast.success("บันทึกสำเร็จ", {
    timeout: 2000,
});
  setTimeout(function () {
              location.reload();
            }, 2000);
            })
          }
        })
        }
        console.log(this.data);

      }
        }
       }
      }
    },
    addstu(){
      this.liststu.push({
      id:parseInt(this.liststu.length)+1,
      no:'',
      name:''
    })
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: gray;
    border-color: gray;
}
</style>
