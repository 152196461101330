<template>
  <div class="mt-5 mb-5">
    <div class="container">
      <div style="text-align:right">
        <a @click="getid(0)">
                <button
                  type="button"
                  class="btn btn-success btn-md"
                  data-bs-toggle="modal"
                  data-bs-target="#AddUser"
                  data-toggle="tooltip" data-placement="top" title="Tooltip on top">
                
               <i class="fa fa-plus"></i> เพิ่มรายวิชา</button
              ></a></div>
              <div >
      <table class="table mt-3" v-for="(l, i) in list" :key="i" >
        <tbody :list="list" is="draggable" tag="tbody" @change="onUnpublishedChangeCourse">
          <tr class="thead-dark" style="cursor: move">
            <th scope="col" style="width:30%">รายวิชา {{ l.name }}</th>
            <th scope="col" style="width:50%">เอกสาร</th>
            <th scope="col" style="width:20%"> 
              <a @click="getidchapter(l.id,0)">
                <button
                  type="button"
                  class="btn btn-success btn-md"
                  data-bs-toggle="modal"
                  data-bs-target="#AddChapter"
                >
                  <i class="fa fa-plus"></i></button
              ></a>&nbsp;&nbsp;
              <a @click="getid(l.id)">
                <button
                  type="button"
                  class="btn btn-warning btn-md"
                  data-bs-toggle="modal"
                  data-bs-target="#AddUser"
                >
                  <i class="fa fa-edit"></i></button
              >&nbsp;&nbsp; <button
                  type="button"
                  class="btn btn-danger btn-md"
                  data-bs-toggle="modal"
                  data-bs-target="#DeleteData"
                >
                  <i class="fa fa-remove"></i></button
              ></a></th>
          </tr>
        </tbody>
        
        <tbody is="draggable" :list="l.chapter" tag="tbody" @change="onUnpublishedChange">
          <tr style="cursor: move" v-for="(c, ci) in l.chapter" :key="ci">
            <td style="width:20%">
              {{ c.name }}
            </td>
            <td style="width:20%">
              <div v-for="(f, fi) in c.file" :key="fi"><a :href="f.path" target="_blank">{{ f.name }}</a> </div>
            </td>
            <td style="width:10%">
              <a @click="getidchapter(l.id,c.id)">
                <button
                  type="button"
                  class="btn btn-warning btn-md"
                  data-bs-toggle="modal"
                  data-bs-target="#AddChapter"
                >
                  <i class="fa fa-edit"></i></button
              >&nbsp;&nbsp; <button
                  type="button"
                  class="btn btn-danger btn-md"
                  data-bs-toggle="modal"
                  data-bs-target="#DeleteChapter"
                >
                  <i class="fa fa-remove"></i></button
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>    
      <!-- <div class="row">
        <div class="col-12" style="text-align: center">
          <button
            class="btn btn btn-success btn-sm"
            style="color: white; text-align: center"
            @click="saveorderlist()"
          >
            Save No
          </button>
        </div>
      </div> -->
    <!-- Modal -->
    <div
      class="modal fade"
      id="AddChapter"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ titlechapter }}</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
                <div class="form-group">
                  <label for="username">Chapter</label>
                  <input
                    v-model="chapter.name"
                    type="text"
                    min="1"
                    class="form-control form-control-sm"
                    id="username"
                  />
              </div>
              <hr/>
              <div class="form-group mt-5">
                  <label for="username">ชื่อไฟล์</label><br/>
                  <input
                    v-model="filename"
                    type="text"
                    min="1"
                    class="form-control form-control-sm mb-3"
                    id="username"
                  />
          <input id="file" class="form-control" type="file" accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf" @change="onFileChange"  />
          <table class="table mt-3">
  <thead class="thead-dark">
    <tr>
      <th scope="col">ชื่อไฟล์</th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="(f, fi) in files" :key="fi">
      <th scope="row" style="width:80%"><a :href="f.path" target="_blank">{{ f.name }}</a> </th>
<td><button
  @click="DeleteFile(f.id)"
                  type="button"
                  class="btn btn-danger btn-md"
                >
                  <i class="fa fa-remove"></i></button
              ></td>
    </tr>
  </tbody>
</table>
              </div>
          </form>
          </div>
          <div class="modal-footer mt-3">
            <button
              type="button"
              class="btn btn-success btn-md"
              @click="savechapter()"
            >
              บันทึก
            </button>
            <button
            id="closedchapter"
              type="button"
              class="btn btn btn-secondary btn-md"
              data-bs-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="AddUser"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
                <div class="form-group">
                  <label for="username">รายวิชา</label>
                  <input
                    v-model="user.name"
                    type="text"
                    min="1"
                    class="form-control form-control-sm"
                    id="username"
                  />
              </div>
          </form>
          </div>
          <div class="modal-footer mt-3">
            <button
              type="button"
              class="btn btn-success btn-md"
              @click="save()"
            >
              Save
            </button>
            <button
            id="closedmenu"
              type="button"
              class="btn btn btn-secondary btn-md"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    
    <div
      class="modal fade"
      id="DeleteFile"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">ยืนยันการลบไฟล์</h5>
          </div>
          <div class="modal-footer mt-3">
            <button
              type="button"
              class="btn btn-danger btn-md"
              @click="deleteFile()"
            >
              ลบ
            </button>
            <button
            id="deletefile"
              type="button"
              class="btn btn btn-secondary btn-md"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="DeleteChapter"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">ยืนยันการลบ {{this.chapter.name}} รายวิชา {{this.user.name}}</h5>
          </div>
          <div class="modal-footer mt-3">
            <button
              type="button"
              class="btn btn-danger btn-md"
              @click="deletechapter()"
            >
              ลบ
            </button>
            <button
            id="closeddeletechapter"
              type="button"
              class="btn btn btn-secondary btn-md"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="DeleteData"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">ยืนยันการลบรายวิชา {{this.user.name}}</h5>
          </div>
          <div class="modal-footer mt-3">
            <button
              type="button"
              class="btn btn-danger btn-md"
              @click="deletedata()"
            >
              Delete
            </button>
            <button
            id="closeddelete"
              type="button"
              class="btn btn btn-secondary btn-md"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "../services/UserService";
import draggable from "vuedraggable";
import LinkService from "../services/LinkService";
import axios from "axios";
import CourseService from "../services/CourseService";
import ChapterService from "../services/ChapterService";
import FileService from "../services/FileService";

export default {
  name: "Nav",
  components: {
    draggable,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      concert_id: 0,
      list: [],
      user: {},
      user_id: 0,
      hash: 0,
      title: "",
      roles: [],
      image:'',
      titlechapter:'',
      chapter:{},
      chapterid:0,
      files:[],
      fileId:0,
      filename:''
    };
  },
  mounted() {
    this.getMenus();
  },
  methods: {
    onUnpublishedChange(){
      for (let l = 0; l < this.list.length; l++) {
        for (let c = 0; c < this.list[l].chapter.length; c++) {
          
        var list = {
          no: c + 1,
        };
        console.log(this.list[l].chapter[c].id, c + 1);
        ChapterService.updateno(this.list[l].chapter[c].id, list).then(() => {
          if (l + 1 == this.list.length) {
            this.getMenus()
          }
        });
          
        }
      }
    },
    onUnpublishedChangeCourse(){
      console.log(this.list);
      for (let l = 0; l < this.list.length; l++) {
        var list = {
          no: l + 1,
        };
        CourseService.updateno(this.list[l].id, list).then(() => {
          if (l + 1 == this.list.length) {
            this.getMenus()
          }
        });
          
        
      }
    },
    deletechapter(){
ChapterService.deletechapter(this.chapterid).then(()=>{
  this.$toast("ลบข้อมูลสำเร็จ", {
    timeout: 2000
});
  document.getElementById("closeddeletechapter").click();
  this.getMenus()
})
    },
    deletedata(){
CourseService.deletecourse(this.user_id).then(()=>{
  this.$toast("ลบข้อมูลสำเร็จ", {
    timeout: 2000
});
  document.getElementById("closeddelete").click();
  this.getMenus()
})
    },
    
    DeleteFile(id){
      FileService.deletefiles(id).then(()=>{
        this.$toast("ลบไฟล์สำเร็จ", {
    timeout: 2000
});
        FileService.getfiless(this.chapterid).then((res) => {
    this.files = res.data
    // console.log(res.data);
  });
      })

    },
    onFileChange(evt) {
      // console.log( this.chapterid);
      // console.log(this.user_id);
      if (this.filename == null || this.filename == '') {
        alert("กรุณากรอกชื่อไฟล์");
        document.getElementById("file").value = "";
      }else{
        if (this.chapterid == 0) {
  if (this.chapter.name == null || this.chapter.name == '') {
        alert("กรุณากรอก Chapter");
        document.getElementById("file").value = "";
      }  else {
        var userdata = {
          name: this.chapter.name,
          courseId:this.user_id,
          createdBy:this.currentUser.id,
          updatedBy:this.currentUser.id,
          status:1,
          no:parseInt(this.list.length)+1
        };
    ChapterService.createchapter(userdata).then((res) => {
this.chapterid = res.data.id
// console.log(res.data.id);
const files = evt.target.files || evt.dataTransfer.files;
      // const selectedImage = evt.target.files[0]; // get first file
      // this.createBase64Image(selectedImage);
      if (!files.length) return;
      this.onUploadFileImage(files[0]);
      // this.createBase64Image(selectedImage);
              });
  
        }
}else{
  const files = evt.target.files || evt.dataTransfer.files;
      // const selectedImage = evt.target.files[0]; // get first file
      // this.createBase64Image(selectedImage);
      if (!files.length) return;
      this.onUploadFileImage(files[0]);
      // this.createBase64Image(selectedImage);
}
      }

     
    },
    onUploadFileImage(selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile); // appending file
      //  sending file to the backend
      //console.log(this.filename);
      var link = LinkService.geturl()
      // console.log(this.data.type);
      const regex = /([\u0E00-\u0E7F]+)/gmu;
const str = selectedFile.name;
let m;
// console.log(selectedFile.type);
let checkthai = ''
let name = selectedFile.name
let typefile = (selectedFile.type).split('/')
while ((m = regex.exec(str)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
        regex.lastIndex++;
    }
    
    // The result can be accessed through the `m`-variable.
    m.forEach(() => {
        // console.log(`Found match, group ${groupIndex}: ${match}`);
checkthai = 1
    });
}
if (checkthai == 1) {
  name = this.makeid(5)+'.'+typefile[1]
}
// console.log(name);
      var http = link + '/uploadimg?name=' + name +'&&path='+this.chapter.name;
      var path = link + '/uploads/'+this.chapter.name+'/'+name;
      axios
        .post(http, formData)
        .then(() => {
var file = {
  chapterId:this.chapterid,
  no:parseInt(this.list.length)+1,
  path:path,
  status:1,
  name:this.filename,
  createdBy:this.currentUser.id,
          updatedBy:this.currentUser.id
}
FileService.createfiles(file).then(()=>{
  // console.log(res.data);
  this.files = []
  FileService.getfiless(this.chapterid).then((res) => {
    this.files = res.data
    // console.log(res.data);
    document.getElementById("file").value = "";
    this.filename = ''
  });
})
          
        })
        .catch((err) => {
          console.log(err);
        });
    },
    saveorderlist() {
      
    },
    getRoles() {
      UserService.getRolesAll().then((res) => {
        // console.log(res.data);
        this.roles = res.data;
      });
    },
    selectcolor(event) {
      this.user.class = event.target.value;
    },
    makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
},

    async getidchapter(id,chapterid){
      this.chapter = {}
  this.chapterid = chapterid
  CourseService.getcourse(id).then((res) => {
          this.user = res.data;
          this.user_id = this.user.id
          // console.log(res.data);
        
if (this.chapterid == 0) {
  this.files = []
  this.titlechapter = 'เพิ่ม Chapter รายวิชา ' + this.user.name
}else{

  this.titlechapter = 'แก้ไข Chapter'
  this.files = []
  FileService.getfiless(this.chapterid).then((res) => {
    this.files = res.data
    // console.log(res.data);
  });
  ChapterService.getchapter(this.chapterid).then((res) => {
          this.chapter = res.data;
          // console.log(res.data);
        });
}
});
},
savechapter(){
  
  if (this.chapter.name == null || this.chapter.name == '') {
        alert("กรุณากรอก Chapter");
      }  else {
        var userdata = {
          name: this.chapter.name,
          courseId:this.user_id,
          createdBy:this.currentUser.id,
          updatedBy:this.currentUser.id,
          status:1,
          no:parseInt(this.list.length)+1
        };
        if (this.chapterid == 0) {
    ChapterService.createchapter(userdata).then(() => {
      this.$toast.success("บันทึกสำเร็จ", {
    timeout: 2000
});
                document.getElementById("closedchapter").click();
                this.getMenus();
              });
  }else{
    ChapterService.updatechapter(this.chapterid,userdata).then(() => {
      this.$toast.success("บันทึกสำเร็จ", {
    timeout: 2000
});
                document.getElementById("closedchapter").click();
                this.getMenus();
              });
  }
        }
},
    getid(id) {
      this.user_id = id;
      if (this.user_id != 0) {
        this.title = "แก้ไขรายวิชา";
        CourseService.getcourse(this.user_id).then((res) => {
          this.user = res.data;
          // console.log(res.data);
        });
      }else{
        this.user = []
        this.title = "เพิ่มรายวิชา";
      }
    },
    save() {
      if (this.user.name == null || this.user.name == '') {
        alert("กรุณากรอกชื่อรายวิชา");
      }  else {
        var userdata = {
          name: this.user.name,
          createdBy:this.currentUser.id,
          updatedBy:this.currentUser.id,
          status:1,
          no:parseInt(this.list.length)+1
        };
        // console.log(userdata);
        if (this.user_id == 0) {
          CourseService.createcourse(userdata).then((res) => {
            this.$toast.success("บันทึกสำเร็จ", {
    timeout: 2000
});
                document.getElementById("closedmenu").click();
                this.user_id = res.data.id
                this.getMenus();
              });
        } else {
          CourseService.updatecourse(this.user_id, userdata).then(() => {
            // console.log(res.data);
              this.$toast.success("บันทึกสำเร็จ", {
    timeout: 2000
});
              document.getElementById("closedmenu").click();
              this.getMenus();
          });
        }
      }
    },
    getMenus() {
      CourseService.getcourses().then((res) => {
        this.list = res.data;
        console.log(res.data);
      });
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
