import http from "../http-common";

class MainService {
  createmain(data) {
    return http.post('/main', data);
  }
  getmains(id) {
    return http.get(`/main?name=`+id);
  }
  getmain(id) {
    return http.get(`/main/` + id);
  }
  updatemain(id, data) {
    return http.put(`/main/${id}`, data);
  }
  updateno(id, data) {
    return http.put(`/main/updateno/${id}`, data);
  }
  deletemain(id) {
    return http.delete(`/main/` + id);
  }
}

export default new MainService();