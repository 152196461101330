<template>
  <div class="container">
    <div class="row mt-5 mb-5">
      <div style="text-align: right" class="mb-3">
        <vue-excel-xlsx
        class="btn btn-success"
        :data="data"
        :columns="columns"
        :file-name="filename"
        :file-type="'xlsx'"
        :sheet-name="'sheetname'"
        >
        <i class="fa fa-file-excel-o"></i> Export
    </vue-excel-xlsx>

    </div>
      <div class="col-md-12" style="text-align: center;">
        <table class="table table-striped" id="tblData">
          <thead>
    <td>#</td>
    <td>รหัสนิสิต</td>
    <td>ชื่อ-นามสกุล</td>
    <td>ชื่อโครงการ</td>
    <td>ชื่ออาจารย์ที่ปรึกษา</td>
    <td>วันที่สอบ</td>
  </thead>
  <tbody>
    <tr class="table-active" v-for="(l,i) in list" :key="i">
      <th>{{i+1}}</th>
    <td style="text-align:left;width:15%">{{l.no}}</td>
    <td style="text-align:left;width:15%">{{l.name}}</td>
    <td>{{l.title}}</td>
    <td style="text-align:left;width:15%">{{l.firstname}}  {{l.lastname}}</td>
    <td style="text-align:center;width:10%">{{l.date}}</td>
    </tr>
  </tbody>
</table>
    </div>
    </div>
  </div>
</template>
<script src="xlsx-js-style"></script>

<script>
import ProjectService from '../services/ProjectService'
import * as XLSX from "xlsx-js-style";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  components:{
  },
  data() {
    return {
      list:[],
      data:[],
      columns:[],
      filename:'รายชื่อนิสิตสอบโครงงาน'
    };
  },
  mounted() {
 this.getbook()
  
  },
  methods: {
    exportTableToExcel() {
      const table = document.querySelector("#dvData table");

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet([]);
      const rows = table.rows;
      // console.log(rows);
      for (let i = 0; i < rows.length; i++) {
        const rowData = [];
        const cells = rows[i].cells;
        for (let j = 0; j < cells.length; j++) {
          const cell = cells[j];

          // attention here
          const nestedTable = cell.querySelector("table");
          if (nestedTable) {
            const nestedRows = nestedTable.rows;
            console.log(nestedRows);
            for (let k = 0; k < nestedRows.length; k++) {
              const nestedCells = nestedRows[k].cells;
              const nestedRowData = [];
              for (let l = 0; l < nestedCells.length; l++) {
                const nestedCell = nestedCells[l];
                nestedRowData.push(nestedCell.innerText);
              }
              rowData.push(...nestedRowData);
            }
          } else {
            // console.log(1);
            rowData.push(cell.innerText);
          }
        }
        XLSX.utils.sheet_add_aoa(worksheet, [rowData], { origin: -1 });
      }
      for (var i in worksheet) {
        // console.log(i);
        if (typeof worksheet[i] != "object") continue;
        let cell = XLSX.utils.decode_cell(i);
        // console.log(cell.c);
        worksheet[i].s = {
          // styling for all cells
          font: {
            name: "cordia new",
            sz: 16,
          },
          alignment: {
            vertical: "left",
            horizontal: "left",
            wrapText: "1", // any truthy value here
          },
          border: {
            right: {
              style: "thin",
              color: "000000",
            },
            left: {
              style: "thin",
              color: "000000",
            },
            top: {
              style: "thin",
              color: "000000",
            },
            bottom: {
              style: "thin",
              color: "000000",
            },
          },
        };
        if ( i == 'A1'||i == 'B1'||i == 'C1' ||i == 'D1' ) {
 //height for row 2
      worksheet[i].s.font= {
        name:'cordia new',
    sz: '16',
    bold:true
  }
  worksheet[i].s.alignment= {
    vertical: 'center',
    horizontal: 'center',
    wrapText: '0', // any truthy value here
  }
}
      }
      var wscols = [{ wch: 100 },{ wch: 100 }, { wch: 20 }, { wch: 20 }];

      worksheet["!cols"] = wscols;
      // console.log(worksheet["A3"]);

      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      const excelFile = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "binary",
      });
      // console.log(workbook);
      var filename = this.description.name + ".xlsx";

      const downloadLink = document.createElement("a");
      downloadLink.href = `data:application/octet-stream;base64,${btoa(
        excelFile
      )}`;
      downloadLink.download = filename;
      downloadLink.click();
    },
    removelast(text){
      return text.substring(0, text.length - 2);
    },
    getbook(){
      ProjectService.getbook().then((res)=>{
        this.list = res.data
        console.log(res.data);
        for (let l = 0; l < this.list.length; l++) {
          this.list[l].no = this.removelast(this.list[l].no)
          this.list[l].name = this.removelast(this.list[l].name)
          this.list[l].date = this.convertdatetothai(this.list[l].date)
          this.list[l].advisorename = this.list[l].firstname +' '+this.list[l].lastname
        }
        this.columns =[
                    {
                        label: "รหัสนิสิต",
                        field: "no",
                    },
                    {
                        label: "ชื่อ-นามสกุล",
                        field: "name",
                    },
                    {
                        label: "ชื่อโครงการ",
                        field: "title",
                    },
                    {
                        label: "ชื่ออาจารย์ที่ปรึกษา",
                        field: "advisorename",
                    },
                    {
                        label: "วันที่สอบ",
                        field: "date",
                    }
                ]
                this.data = this.list
      })
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: gray;
    border-color: gray;
}
</style>
