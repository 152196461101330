import { render, staticRenderFns } from "./ProjectManage.vue?vue&type=template&id=a9964376&scoped=true"
import script from "./ProjectManage.vue?vue&type=script&lang=js"
export * from "./ProjectManage.vue?vue&type=script&lang=js"
import style0 from "./ProjectManage.vue?vue&type=style&index=0&id=a9964376&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a9964376",
  null
  
)

export default component.exports