<template>
  <div>
    <div>
      <!-- Image and text -->
      <nav class="navbar navbar-expand-sm navbar-light bg-light" v-if="show">
        <a class="navbar-brand" href="#"></a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto" v-if="currentUser">
            <li class="nav-item" v-for="m in menu" :key="m.id" :class="m.class">
        <a class="nav-link" :href="m.url">{{m.name}}</a>
      </li>
          </ul>
          <ul class="navbar-nav mr-auto" v-else>
            <li class="nav-item active">
        <a class="nav-link" href="/">หน้าหลัก</a>
      </li>
          </ul>
            <a href="/login" v-if="!currentUser">
              <button
                class="btn btn-outline-success"
              >
                Login
              </button></a
            >
            <a @click="logOut()" v-else>
              <button
                class="btn btn-outline-danger"
              >
                Logout
              </button></a
            >
        </div>
      </nav>
    </div>
    <router-view />
  </div>
</template>


<script>
import UserService from "./services/UserService";

export default {
  name: "app",
  components: {},
  data() {
    return {
      show: true,
      menu: [],
      active:'active',
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    logOut() {

      this.$router.push("/");
      this.$store.dispatch("auth/logout");
      // setTimeout(function () {
      //   location.reload();
      // }, 500);
    },
  },
  mounted() {
    console.log(this.$route.name);
    if (this.$route.name == 'project') {
      this.show = false
    }
    if (this.currentUser) {
      this.active = ''
      UserService.getMenubyRoleID(this.currentUser.role_id).then((res) => {
        this.menu = res.data;
        // console.log(this.menu);
        for (let m = 0; m < this.menu.length; m++) {
          this.menu[m].class = "";
          if (this.menu[m].url == this.$route.path) {
            this.menu[m].class = "active";
          }
        }
        //   this.menu.push({
        //     name:'รายการสินค้าที่ตรวจสอบทั้งหมด',
        // url:'/scraping',
        // class:''
        //   })
      });
    }else{
      if (this.$route.name == null) {
      this.$router.push('/home')
    }
    }
  },
};
</script>

<style>
/* Add a black background color to the top navigation */
.topnav {
  background-color: #333;
  overflow: hidden;
}

/* Style the links inside the navigation bar */
.topnav a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

/* Change the color of links on hover */
.topnav a:hover {
  background-color: #ddd;
  color: black;
}

/* Add a color to the active/current link */
.topnav a.active {
  background-color: #808000;
  color: white;
}
.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
    color: blue;
}
body {
    font-family: 'Prompt';
}
</style>