import http from "../http-common";

class CourseService {
  createcourse(data) {
    return http.post('/course', data);
  }
  getcourses() {
    return http.get(`/course`);
  }
  getcourse(id) {
    return http.get(`/course/` + id);
  }
  updatecourse(id, data) {
    return http.put(`/course/${id}`, data);
  }
  updateno(id, data) {
    return http.put(`/course/updateno/${id}`, data);
  }
  deletecourse(id) {
    return http.delete(`/course/` + id);
  }
}

export default new CourseService();