import http from "../http-common";

class FileService {
  createfiles(data) {
    return http.post('/files', data);
  }
  getfiless(id) {
    return http.get(`/files?name=`+id);
  }
  getfiles(id) {
    return http.get(`/files/` + id);
  }
  updatefiles(id, data) {
    return http.put(`/files/${id}`, data);
  }
  updateno(id, data) {
    return http.put(`/files/updateno/${id}`, data);
  }
  deletefiles(id) {
    return http.delete(`/files/` + id);
  }
}

export default new FileService();