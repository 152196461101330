import http from "../http-common";

class ProjectService {
  createbook(data) {
    return http.post('/book', data);
  }
  createstu(data) {
    return http.post('/stu', data);
  }
  getdate() {
    return http.get(`/dateproject`);
  }
  getcounts(mood,month,year,userId) {
    return http.get(`/counts?moodId=`+mood+'&&month='+month+"&&year="+year+"&&userId="+userId);
  }
  getbook() {
    return http.get(`/book`);
  }
  getadvisor() {
    return http.get(`/advisor`);
  }
}

export default new ProjectService();