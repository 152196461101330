<template>
  <div class="container">
    <div class="row mt-5 mb-5">
      <div class="col-md-3" style="text-align: center;">
        <img :src="data.image" alt="Avatar" style="height:120px">
      </div>
      <div class="col-md-9">
        <div>
          <span v-html="data.name"></span></div>
      </div>
    </div>
    <!-- <div class="col-md-12 col-md-offset-1 mt-5">
        <div style="text-align:center">
          
          
          <hr />
        </div>
    </div> -->
    <hr />
  <div class="row mt-5">
    <div class="col-md-4" v-for="l in list" :key="l.id">
    <div class="list-group mb-5">
      <button type="button" class="list-group-item list-group-item-action active " style="background-color: #0d6efd;border-color: #0d6efd;text-align: center;">
        รายวิชา {{l.name}}
  </button>
    <div class="" v-for="c in l.chapter" :key="c.id">
  <button type="button" class="list-group-item list-group-item-action active ">
    {{c.name}}
  </button>
  <button type="button" class="list-group-item list-group-item-action">
    <li class="d-flex justify-content-between align-items-center mb-3" v-for="f in c.file" :key="f.id">
      {{f.name}}
      <a :href="f.path" target="_blank"><h5><span class="badge badge-primary badge-pill">ดาวน์โหลด</span></h5></a> 
  </li></button>
</div>
</div>
  </div>
</div>
</div>
</template>

<script>
import CourseService from '../services/CourseService'
import MainService from '../services/MainService'

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  components:{
  },
  data() {
    return {
      list:[],
      data:{}
    };
  },
  mounted() {
    console.log(this.$route);
    MainService.getmain(1).then((res) => {
      this.data = res.data
    })
    CourseService.getcourses().then((res) => {
      this.list = res.data
      console.log(res.daya);
    })
  
  },
  methods: {
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: gray;
    border-color: gray;
}
</style>
